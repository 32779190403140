import React from 'react';
import ourProducts from '../../Component Library/svg/producthome.png';
import ourProductsphone from '../../Component Library/ourproductcs.jpg';
import { Link } from "react-router-dom";

const OurProducts = () => {
  const backgroundStyle = {
    backgroundImage: `url(${ourProducts})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  const backgroundStylemb = {
    backgroundImage: `url(${ourProductsphone})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };


  return (
    <div>
      {/* desktop section start */}
    <div className='desktop hidden xl:block'>
      
    <div className='flex h-[990px] w-screen items-center justify-center flex-col gap-[40px] mt-[-6rem] 2xl:mt-[1px] md:mt-[378px]' >
      <div className='w-full h-[10%] flex items-center justify-center 2xl:text-[55px] md:text-[45px] 2xl:mt-[-25px] md:mt-[15px] sm:text-[25px] font-semibold font-poppins text-[#252525]'>Our Products</div>
 
    <div className='flex flex-row rounded-[34px] items-center justify-center px-[20px] py-[20px] w-[89%] h-[81%] font-poppins 2xl:mt-[5px] md:mt-[-25px] ' style={backgroundStyle}  >
        <div className='w-[10%] h-full '></div>
        <div className='flex flex-col justify-center xl:w-[50%] sm:w-[100%] h-[100%] font-semibold gap-[20px]'>
            <div className='flex flex-col leading-[60px] sm:w-[100%] xl:ml-[-54px]  xl:text-left sm:text-center'>
                <div className='text-white 2xl:text-[73px] md:text-[64px] sm:text-[23px] font-medium font-poppins leading-none tracking-customs1'>Sculpting Exotic</div>
                <div className='text-white 2xl:text-[73px] md:text-[64px] sm:text-[23px] font-medium font-poppins leading-none tracking-customs1'>Threads to</div>
                <div className='text-[#FF7B1B] font-bold 2xl:text-[68px] md:text-[59px] sm:text-[23px] font-poppins leading-none tracking-customs1'>Excellence</div> 
            </div> 

            <div className='text-white text-[20px] font-light font-poppins ml-[-54px]'>Our Fusion Fabric Techniques for Kids,<br></br>Ladies, and Men's</div>
            <Link  to="/products">
            <div className='text-[#FF7B1B] text-[25px] font-medium font-Archivo ml-[-54px]'> Explore More <span className='ml-2 text-orange-500'>&gt;</span>  </div>
            </Link>
        </div>
       
        <div className='w-[40%] h-full '></div>
    </div>


 
      
    </div>
    </div>
      {/* desktop section end */}

          {/* Mobile section start */}
          <div className='mobile xl:hidden sm:block mt-[-974px]'>
            <div className='text-[20px] font-semibold font-poppins text-center mb-[22px] '>Our Products</div>
           <div className='w-full h-[575px] mt-[-52px] ' style={backgroundStylemb}>
              <div className='text-[#e7e7e7] text-[24px] font-medium font-poppins text-center pt-[94px]'>Sculpting Exotic Threads to </div>
              <div className='text-[#FF7B1B] text-[24px] font-semibold font-poppins text-center'>Excellence</div>
              <div className='text-white font-poppins font-light text-[12px] text-center w-[78%] mx-auto mt-[15px]'>Our Fusion Fabric Techniques for Kids,<br></br> Ladies, and Men's</div>
              <Link  to="/products">
            <div className='text-[#FF7B1B] text-[15px] font-medium font-Archivo mx-auto flex items-center justify-center mt-[14px] '> Explore More <span className='ml-2 text-orange-500'>&gt;</span>  </div>
            </Link>
           </div>
          </div>
          {/* Mobile section End */}

    </div>
  );
};

export default OurProducts;
