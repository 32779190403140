import React from 'react';

import stichbg1 from '../../Component Library/capacitych.png';
import capacity from '../../Component Library/ourcapacity.png';


import { Link } from 'react-router-dom';
const FouthPage = () => {
    const backgroundStyle = {
        backgroundImage: `url(${capacity})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',



    };


    const backgroundStyle1 = {
        backgroundImage: `url(${stichbg1})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',



    };

    return (
        <div className='flex items-center justify-center xl:h-[91vh] sm:h-[111vh] w-screen mt-0 mb-0'>

            <div style={backgroundStyle} className=' flex flex-col rounded-[50px] items-center justify-center px-[20px] py-[20px] w-[91%] h-[65%] hidden xl:block 2xl:mt-[18px] md:mt-[928px] ' >
                <div className='w-full h-[40%] flex-col flex items-center justify-center mt-[22px]'>
                    <div className='2xl:text-[44px] md:text-[32px] sm:text-[20px] text-white font-semibold font-poppins'>
                        Our Capacity
                    </div>
                    <div className='2xl:text-[39px] md:text-[32px] sm:text-[20px] 2xl:mt-[0px]  md:mt-[9px] sm:mt-[12px] font-light font-poppins text-[#A9A9A9]'>
                        8,00,000 pieces/year
                    </div>
                    <Link to="/infrastructure">
                        <div className='flex items-center text-[#D65B03] 2xl:text-[27px] md:text-[21px] sm:text-[18px]  font-light font-poppins mt-[12px]'>
                            Explore More
                            <span className='ml-2 text-orange-500'>&gt;</span>
                        </div>
                    </Link>
                </div>
                <div className='w-full h-[60%] flex xl:flex-row sm:flex-col  items-center justify-center 2xl:mt-[-22px] md:mt-[-41px]'>
                    <div className='flex items-center justify-center w-[25%] h-full flex-col 2xl:mt-[1px] md:mt-[59px]'>
                        <div className='2xl:text-[55px] md:text-[38px]  font-bold font-Archivo text-[#FF7B1B]'>State of Art</div>
                        <div className='2xl:text-[28px] md:text-[18px] font-light text-white flex flex-col items-center justify-center font-causten 2xl:leading-9 md:leading-7'>
                            <div>Manufacturing </div>
                            <div>Facilities</div>
                        </div>
                    </div>
                    <div className='bg-[#acacac] w-[1px] h-[34%] mt-[65px] ml-[44px] '></div>
                    <div className='flex items-center justify-center w-[25%] h-full flex-col 2xl:mt-[1px] md:mt-[59px]'>
                        <div className='2xl:text-[55px] md:text-[38px] font-bold font-Archivo text-[#FF7B1B]'>6</div>
                        <div className='2xl:text-[28px] md:text-[18px] font-light text-white flex flex-col items-center justify-center font-causten 2xl:leading-9 md:leading-7'>
                            <div>Fully Equipped  </div>
                            <div>Production Line Setup</div>
                        </div>
                    </div>
                    <div className='bg-[#acacac] w-[1px] h-[34%] mt-[65px] mr-[44px]'></div>
                    <div className='flex items-center justify-center w-[25%] h-full flex-col 2xl:mt-[1px] md:mt-[59px]'>
                        <div className='2xl:text-[55px] md:text-[38px] font-bold font-Archivo text-[#FF7B1B]'>13M+</div>
                        <div className='2xl:text-[28px] md:text-[18px] font-light text-white flex flex-col items-center justify-center font-causten 2xl:leading-9 md:leading-7'>
                            <div>Garments Produced   </div>
                            <div>And Shipped Over</div>
                        </div>
                    </div>
                </div>



                {/* <div className='flex flex-col bg-black h-[444px] w-full'>
                    <div className='flex w-[40%] h-full bg-red-400'></div>
                    <div className='flex w-[20%] h-full bg-pink-400'></div>
                    <div className='flex w-[40%] h-full bg-red-400'></div>
                </div> */}

            </div>


             


             
            {/* mobile section start */}
            <div className='flex w-full h-[478px] bg-red-400 hidden sm:block xl:hidden lg:hidden rounded-[32px] mt-[-1594px] w-[89%] mx-auto' style={backgroundStyle1} >
            <Link to="/infrastructure">
                    <div className='flex items-center text-[#D65B03]  text-[18px]  font-light font-poppins mt-[132px] items-center justify-center'>
                        Explore More
                        <span className='ml-2 text-orange-500'>&gt;</span>
                    </div>
                </Link>
                {/* <div className='text-white text-center text-[25px] font-semibold mt-[53px]'>Our Capacity</div>
                <div className='text-[20px] mt-[0px] mt-[17px] font-light font-poppins text-[#A9A9A9] flex items-center justify-center'>
                    3,50,000 pieces/month
                </div>
                <Link to="/infrastructure">
                    <div className='flex items-center text-[#D65B03]  text-[18px]  font-light font-poppins mt-[12px] items-center justify-center'>
                        Explore More
                        <span className='ml-2 text-orange-500'>&gt;</span>
                    </div>
                </Link>
                <div className='flex flex-row h-[30%] w-full  items-center justify-center'>
                    <div className='flex flex-col text-[24px] text-center text-[#FF7B1B] font-bold'>State <br></br> Of Art</div>
                    <div className='bg-[#E8E8E8] w-[1px] h-[14%] mt-[9px] ml-[32px]'></div>
                    <div className='flex flex-col text-white text-[13px] text-center ml-[24px] text-[#FF7B1B]'>Manufacturing facility</div>
                </div>
                <div className='flex flex-row h-[30%] w-full  items-center justify-center mt-[-74px]'>
                    <div className='flex flex-col text-[24px] text-center text-[#FF7B1B] font-bold ml-[-24px] pl-[44px]'>6</div>
                    <div className='bg-[#E8E8E8] w-[1px] h-[14%] mt-[9px] ml-[94px]'></div>
                    <div className='flex flex-col text-white text-[13px] text-center ml-[24px] text-[#FF7B1B]'>Fully Equipped Production <br></br>  Line Setup</div>
                </div>
                <div className='flex flex-row h-[30%] w-full items-center justify-center mt-[-74px]'>
                    <div className='flex flex-col text-[24px] text-center text-[#FF7B1B] font-bold ml-[32px]'>13M+</div>
                    <div className='bg-[#E8E8E8] w-[1px] h-[14%] mt-[9px] ml-[42px]'></div>
                    <div className='flex flex-col text-white text-[13px] text-center ml-[24px] text-[#FF7B1B]'>Garments produced and<br></br> shipped over</div>
                </div> */}

            </div>


            {/* mobile section end*/}







        </div>
    );
};

export default FouthPage;
