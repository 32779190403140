import React from "react";
import infrabanner from '../../Component Library/svg/infrabanner.jpg'
import { NavBar } from "../Components/navBar";
import stateart from '../../Component Library/stateofart.png'
import handsake from '../../Component Library/collabratershandsake.png'
import certificate from '../../Component Library/certificate.svg'
import trust from '../../Component Library/trust.svg'
import endtoend from '../../Component Library/endtoend.svg'
import fblogo from '../../Component Library/fblogo.svg'
import linkedinlogo from '../../Component Library/linkedinlogo.svg'
import youtubelogo from '../../Component Library/youtubelogo.svg'
import sunknitLogo from '../../Component Library/sunKnitLogo.svg'
import infrabgimage1 from '../../Component Library/Rectangle1.png'
import infrabgimage2 from '../../Component Library/Rectangle2.png'
import infrabgimage3 from '../../Component Library/Rectangle3.png'
import infrabgimage4 from '../../Component Library/Rectangle4.png'
import infrabgimage7 from '../../Component Library/Rectangle7.png'
import infrabgimage5 from '../../Component Library/Rectangle5.png'
import infrabgimage6 from '../../Component Library/Rectangle6.png'
import infrabgimage8 from '../../Component Library/Rectangle8.png'
import infrasquare1 from  '../../Component Library/svg/infrasquare1.jpg'
import infrasquare2 from  '../../Component Library/svg/infrasquare2.jpg'
import infrasquare3 from  '../../Component Library/svg/infrasquare3.jpg'
import infrasquare4 from  '../../Component Library/svg/infrasquare4.jpg'
import { Link } from "react-router-dom";
import sweing from '../../Component Library/svg/sweing.svg'
import ship from '../../Component Library/ship.png'
import bgbanner1 from '../../Component Library/svg/phonebannerinfra.png'
import { NavBar1 } from "../Components/navBar1";
import { useEffect } from "react";
import Footer from "../Components/footer";
import MobileNavbar from "../Components/navbarMobile";
import { FooterMobile } from "../Components/fotterMobile";

export const InfraPage1 = () => {
    const backgroundStyle = {
        backgroundImage: `url(${infrabanner})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        width: '100%'
    };


    const backgroundStyle1 = {
        backgroundImage: `url(${infrabgimage1})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',

    };


    const backgroundStyle2 = {
        backgroundImage: `url(${infrabgimage2})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',

    };

    const backgroundStyle3 = {
        backgroundImage: `url(${infrabgimage3})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',

    };

    const backgroundStyle4 = {
        backgroundImage: `url(${infrabgimage4})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',

    };


    const backgroundStyle5 = {
        backgroundImage: `url(${infrabgimage5})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',

    };

    const backgroundStyle6 = {
        backgroundImage: `url(${infrabgimage6})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',

    };

    const backgroundStyle7 = {
        backgroundImage: `url(${infrabgimage7})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',

    };

    const backgroundStyle8 = {
        backgroundImage: `url(${infrabgimage8})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',

    };


    const backgroundStylePhone = {
        backgroundImage: `url(${bgbanner1})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',

    };


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (

        <div>
            {/* desktop view*/}
            <div className='flex flex-col gap-[25px]  w-screen hidden xl:block '>
                <div style={backgroundStyle} className="rounded-b-[50px] ml-[0px]">
                    <NavBar1 />

                    <div className='flex flex-col gap-[10px]  px-[20px] py-[20px] 2xl:mt-[541px] md:mt-[351px] ml-[72px] 2xl:leading-[6rem] md:leading-[3.2rem]' >
                        <div className='2xl:text-[96px] md:text-[56px] font-gilroy font-bold text-white flex  mr-[44px]  xl:mt-[-42px] 2xl:tracking-customs1 ' > Excellence in a <br></br>Warm Infrastructure</div>
                        <div className='2xl:text-[40px] md:text-[22px] font-gilroy text-white flex mr-[44px] mb-[54px]' style={{ fontWeight: 'light' }}> Where Precision blends with Style.</div>
                    </div>

                </div>

                <div className="w-[89%] flex py-8 mt-[54px]">
                    <div className="flex flex-col ml-[242px]">
                        <div className="2xl:text-[72px] md:text-[60px] font-semibold text-[#252525] font-poppins leading-[1.7rem]">Infrastructure for World Class</div>
                        <div className="2xl:text-[72px] md:text-[60px] font-semibold text-[#252525] font-poppins">Apparel Appeal </div>
                        
                        <div className="2xl:text-[25px] md:text-[22px] font-normal text-[#33333] w-[107%] font-poppins">Embracing modern technology, eco-friendly processes and ethical production, our structure embodies a commitment to quality and integrity. Our infrastructure is tailored for world-class appeal, ensuring your apparel meets the highest standards of excellence.</div>
                    </div>



                </div>



                <div className="flex gap-[21px] ml-[306px] mb-[22px]">
                    <div className="flex xl:flex-col sm:flex-row  2xl:w-[322px] 2xl:h-[303px] md:w-[197px] md:h-[187px] rounded-[25px]" style={backgroundStyle1}></div>
                    <div className="flex flex-col 2xl:w-[322px] 2xl:h-[303px] md:w-[197px] md:h-[187px] rounded-[25px]" style={backgroundStyle2}></div>
                    <div className="flex flex-col 2xl:w-[623px] 2xl:h-[303px]  md:w-[503px] md:h-[193px] rounded-[25px] bg-[#FFF1E8]  2xl:mt-[12px] ">
                        <div className=" 2xl:text-[32px] md:text-[17px] font-poppins font-bold ml-[50px] 2xl:mt-[55px] md:mt-[42px]">Organised Production Lines:</div>
                        <div className=" 2xl:text-[16px] md:text-[12px] font-poppins font-normal ml-[50px]  2xl:mt-[12px] md:mt-[2px] w-[99%]">Sun Knits boasts six fully equipped production line setups,<br></br>  ensuring a seamless CMT process. Our state-of-the-art<br></br> facilities include a range of specialised equipment,<br></br> enabling precision in executing tasks of all levels with <br></br> utmost efficiency.</div>
                    </div>
                </div>

                <div className="flex gap-[21px] 2xl:ml-[306px] md:ml-[296px] mt-[14px]">
                    <div className="flex flex-col 2xl:w-[322px] 2xl:h-[303px] md:w-[197px] md:h-[187px] rounded-[25px]" style={backgroundStyle3}></div>
                    <div className="flex flex-col 2xl:w-[623px] 2xl:h-[303px]  md:w-[503px] md:h-[193px]  rounded-[25px] bg-[#f1f1f1]">
                        <div className="2xl:text-[32px] md:text-[17px] text-center font-poppins font-bold ml-[30px] 2xl:mt-[42px] md:mt-[29px] leading-[36px]">Top-Tier Merchandising/ IT Executives:</div>
                        <div className="2xl:text-[16px] md:text-[12px]  text-center font-poppins font-normal ml-[12px] 2xl:mt-[13px] md:mt-[3px] px-8">At Sun Knits, our exceptional IT and merchandising team<br></br> possesses unmatched sourcing capabilities and a relentless<br></br> drive for innovation. We bring a distinctive approach to the<br></br> table, coupled with seamless task recording for unparalleled<br></br> operational efficiency.</div>
                    </div>
                    <div className="flex flex-col 2xl:w-[322px] 2xl:h-[303px] md:w-[197px] md:h-[187px] rounded-[25px]" style={backgroundStyle4}></div>
                </div>

                <div className="flex gap-[21px] ml-[306px] mt-[14px]">
                    <div className="flex flex-col 2xl:w-[623px] 2xl:h-[303px]  md:w-[503px] md:h-[193px]  rounded-[25px] bg-[#E8F1FF]">
                        <div className=" 2xl:text-[32px] md:text-[17px] font-poppins font-bold 2xl:mt-[54px] md:mt-[29px]  mr-[50px] text-right">Lounging & Display Facility:</div>
                        <div className=" 2xl:text-[16px] md:text-[12px]  font-poppins font-normal ml-[22px] 2xl:mt-[18px] md:mt-[9px] mr-[50px] text-right">At Sun Knits, our commitment extends beyond production<br></br> infrastructure. Experience our products in an elegantly curated<br></br> display area, complemented by a dedicated lounging space.<br></br> Buyers can relax and engage in real-time, hands-on<br></br> exploration of our distinguished portfolio.</div>
                    </div>
                    <div className="flex flex-col 2xl:w-[322px] 2xl:h-[303px] md:w-[197px] md:h-[187px] rounded-[25px]" style={backgroundStyle5}></div>
                    <div className="flex flex-col 2xl:w-[322px] 2xl:h-[303px] md:w-[197px] md:h-[187px] rounded-[25px]" style={backgroundStyle6}></div>

                </div>


                <div className="flex gap-[21px] ml-[306px] mt-[14px] mb-[22px]">
                    <div className="flex flex-col 2xl:w-[322px] 2xl:h-[303px] md:w-[197px] md:h-[187px] rounded-[25px]" style={backgroundStyle7}></div>
                    <div className="flex flex-col 2xl:w-[623px] 2xl:h-[303px]  md:w-[503px] md:h-[193px] rounded-[25px] bg-[#FFF2E9]">
                        <div className=" 2xl:text-[32px] md:text-[17px] text-center font-poppins font-bold ml-[22px] 2xl:mt-[59px] md:mt-[29px] ">Safety Protocols:</div>
                        <div className=" 2xl:text-[16px] md:text-[12px]  text-center font-poppins font-normal ml-[12px] 2xl:mmt-[18px]  px-8">At Sun Knits, safety is paramount. Our meticulous efforts ensure a secure environment, equipped with advanced fire extinguishers and dedicated safety measures. From spacing considerations to comprehensive precautions, we prioritise the well-being of our workforce and workspace.</div>
                    </div>
                    <div className="flex flex-col 2xl:w-[322px] 2xl:h-[303px] md:w-[197px] md:h-[187px] rounded-[25px]" style={backgroundStyle8}></div>
                </div>


                <div className="flex w-full 2xl:h-[835px] md:h-[645px] bg-green-200">
                    <div className="flex flex-col w-[50%] bg-black">
                        <div className="mx-[80px] 2xl:my-[174px] md:mt-[127px]">
                            <div className="2xl:text-[64px] md:text-[45px] font-normal text-[#FF7E20] font-poppins leading-tight"><span className="font-bold">Weaving Revolution </span> in Apparel Durability</div>
                            <div className="2xl:text-[28px] md:text-[21px]  font-normal text-white my-[48px] font-poppins">Redefines Durability Standards in Every Stitch</div>
                            <div className="2xl:text-[21px] md:text-[18px] font-normal text-[#c1c1c1] w-[84%] font-poppins 2xl:mt-[17px] md:mt-[-27px] ">To explore the pinnacle of trendsetting innovation at Sun Knit Fashions, take a glance at a sizable sector that combines the latest technology with skilful construction. Perfect curves and stitches are delivered by imported machinery and our spacious, high-tech unit breathes warmth.</div>
                        </div>
                    </div>
                    <div className="flex flex-col w-[50%] bg-black">
                        <div className="h-[15%] w-full mt-[84px] ml-[-45px]">
                            <div className="flex items-center justify-center 2xl:text-[44px] md:text-[41px]  text-[#e9e9e9] font-semibold font-poppins">Our Capacity</div>
                            <div className="flex items-center justify-center 2xl:text-[39px] md:text-[35px] text-[#a9a9a9] font-normal font-poppins mt-[11px]">3,50,000 pieces/month</div>
                        </div>
                        <div className="h-[33%] w-full flex items-center justify-center">
                            <div className="flex w-[50%]  justify-start items-center 2xl:ml-[1px] md:ml-[-152px]">
                                <img src={stateart}  className="mr-4 2xl:w-[76px] 2xl:h-[78px] md:w-[48px] md:h-[48px] " alt="State of Art" />
                                <div className="ml-[24px]">
                                    <div className="2xl:text-[45px] md:text-[41px] font-bold font-Archivo text-white 2xl:tracking-customs2 md:tracking-customs">State of Art</div>
                                    <div className="2xl:text-[24px] md:text-[21px] font-normal text-[#c1c1c1] font-poppins 2xl:ml-[32px] md:ml-[5px]">Manufacturing Facility</div>
                                </div>
                            </div>

                        </div>
                        <div className="border-t 2xl:w-[738px] md:w-[538px] 2xl:mt-[1px] md:mt-[-42px] ml-[23px] border-gray-600"></div>
                        <div className="h-[34%] 2xl:w-[1206px] md:w-[671px] 2xl:md-[11px] 2xl:ml-[-141px]  md:ml-[-91px]  2xl:mt-[px] md:m-[-22px] [bg-black flex">
                            <div className="flex w-[50%] justify-start items-center relative pr-4">
                                <img src={sweing}  className="mr-4 2xl:w-[141px] 2xl:h-[73px] md:w-[78px] md:h-[48px]" alt="State of Art" />
                                <div className="ml-[14px]">
                                    <div className="2xl:text-[45px] md:text-[41px]  font-bold font-Archivo text-white 2xl:text-center 2xl:ml-[-9px] md:ml-[54px]">6</div>
                                    <div className="2xl:text-[25px] md:text-[17px] font-normal  font-poppins text-center 2xl:w-[114%] ml-[-34px] mr-[58px] text-[#c1c1c1] ">Fully Equipped<br></br> Production Line Setup</div>
                                </div>
                                <div className="border-r-2 border-white h-[74px] absolute right-0 mr-[31px]"></div>
                            </div>

                            <div className="flex w-[50%] justify-start items-center pl-7">
                                <img src={ship}  className="mr-42 xl:w-[91px] 2xl:h-[88px] md:w-[38px] md:h-[48px] " alt="State of Art" />
                                <div className="ml-[44px]">
                                    <div className="2xl:text-[45px] md:text-[41px] font-bold font-Archivo text-white text-center">13M+</div>
                                    <div className="2xl:text-[25px] md:text-[17px] font-normal  font-poppins text-center text-[#c1c1c1] ">garments produced <br></br> and shipped over</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>






                <div className="h-[1477px] ">
                    <div className="h-3/5">
                        <div className="flex font-semibold 2xl:text-[75px] md:text-[60px] text-black items-center justify-center my-[34px] font-poppins"> Collaborators</div>
                        <div className="flex items-center justify-center 2xl:text-[31px] md:text-[22px]  font-light w-[85%] text-center mx-auto mt-[-22px] font-poppins 2xl:leading-[3.3rem] md:leading-[2.8rem]">At Sun Knit Fashions, our commitment to quality extends beyond our walls. Collaborating with highly efficient subcontractors equipped with top-of-the-line knitting, dyeing, printing facilities, and whatever process that is associated with apparel execution, we set new industry standards. Our partners share our sustainable vision, holding certifications that validate our collective dedication to ethical and high-quality production. Together, we redefine excellence in the apparel industry.</div>
                        <div className="flex justify-center items-center my-[74px]">
                            <img src={handsake} className="w-[31%]" />
                        </div>
                    </div>

                    <div className="h-1/5 flex items-center justify-center  ">
                        <div className='flex items-center justify-center xl:w-[25%] sm:w-[100%] h-full xl:flex-col sm:flex-row p-8'>
                            <div>
                                <img src={certificate} className="mr-[123px] md:w-[72px] 2xl:w-[102px]  h-[122px] mt-[-27px] " />
                            </div>
                            <div className='2xl:text-[37px] md:text-[25px] font-medium text-[#33333] mt-[14px] font-poppins mr-[112px]'>Certifications</div>
                            <div className='2xl:text-[15px] md:text-[12px] font-normal text-white flex flex-col items-center justify-center font-poppins'>
                                <div className="text-black text-center mx-auto 2xl:leading-[2.1rem] md:leading-[1.4rem] ml-[-62px] w-full mt-[37px]">At Sun Knit Fashions, our aligned vendors hold GOTS and OEKO-TEX certifications, reinforcing our commitment to sustainability and ethical practices.</div>
                            </div>
                        </div>
                        <div className='bg-[#333] w-[1px] h-[45%] mr-[54px] mt-[34px]'></div>
                        <div className='flex items-center justify-center xl:w-[25%] sm:w-[100%] h-full xl:flex-col sm:flex-rowp-8'>
                            <div>
                                <img src={trust} className="mt-[12px] md:w-[82px] 2xl:w-[122px] "/>
                            </div>
                            <div className="">
                            <div className='2xl:text-[37px] md:text-[25px] font-medium text-[#33333]  2xl:my-[24px]  md:mt-[22px]  font-poppins text-center'>Trust</div>
                            <div className='2xl:text-[15px] md:text-[12px] font-normal text-white flex flex-col items-center justify-center font-poppins 2xl:mt-[1px] md:mt-[47px]'>
                                <div className="text-black text-center mx-auto 2xl:leading-[2.1rem] md:leading-[1.4rem] w-full">For decades, our enduring partnerships are built on trust,<br></br> with Sun Knit Fashions consistently ensuring quality and seamless workflows, fostering lasting relationships with our valued partners.</div>

                            </div>
                            </div>
                           
                        </div>
                        <div className='bg-[#333] w-[1px] h-[45%] ml-[54px] mt-[34px]'></div>
                        <div className='flex items-center justify-center xl:w-[25%] sm:w-[100%] h-full xl:flex-col sm:flex-row p-8'>
                            <div>
                                <img src={endtoend} className="ml-[123px] md:w-[72px] 2xl:w-[102px]" />
                            </div>
                            <div className='2xl:text-[37px] md:text-[25px] font-medium text-[#33333] my-[24px] ml-[112px]'>End To End</div>
                            <div className='2xl:text-[15px] md:text-[12px]  font-normal text-white flex flex-col items-center justify-center font-poppins'>
                                <div className="text-black text-center mx-auto 2xl:leading-[2.1rem] md:leading-[1.4rem] mr-[-97px] 2xl:mt-[1px] md:mt-[24px]">From inception to completion, our expert partners provides tailored solutions for every aspect of the garmenting process, exceeding your expectations.</div>
                            </div>
                        </div>
                    </div>

                </div>

                <Footer />

            </div>


            {/* mobile view start*/}

            <div className="xl:hidden sm:block">
            <div>
           <MobileNavbar/>
           </div>
                <div className="relative w-full h-[657px] rounded-b-[24px]" style={backgroundStylePhone}>
                    <div className="absolute bottom-0 right-0 p-4 text-white font-gilroy leading-[2.2rem]">
                        <div className="text-[32px] ml-[77px]">Excellence in a</div>
                        <div className="text-[32px]">Warm Infrastructure</div>
                        <div className="font-poppins ml-[15px]">– Where Precision blends with Style</div>
                    </div>
                </div>
                <div className="ml-[32px]">
                    <div className="text-[18px] font-semibold text-[#252525] font-poppins leading-[1.7rem] mt-[22px]">Infrastructure for World Class</div>
                    <div className="text-[18px] font-semibold text-[#252525] font-poppins">Apparel Appeal:</div>
                    <div className="text-[12px] font-normal text-[#33333] mr-[5px] font-poppins leading-[1.5rem] ">Embracing modern technology, eco-friendly processes and ethical production, our structure embodies a commitment to quality and integrity. Our infrastructure is tailored for world-class appeal, ensuring your apparel meets the highest standards of excellence.</div>
                </div>
                 
                 <div className="flex-box">
                 <div className="flex flex-col w-[95%] mx-auto h-[144px]  rounded-[25px] bg-[#FFF1E8] mt-[23px]">
                        <div className=" text-[16px] font-poppins font-bold ml-[22px] mt-[19px]">Organised Production Lines:</div>
                        <div className=" text-[10px] font-poppins font-normal ml-[22px] mt-[3px] w-[87%] leading-[1 rem]">Sun Knits boasts six fully equipped production line setups,<br></br> ensuring a seamless CMT process. Our state-of-the-art<br></br> facilities include a range of specialised equipment, enabling<br></br> precision in executing tasks of all levels with utmost<br></br> efficiency.</div>
                    </div>
                    <div>
                    <img src={infrasquare1} className="w-[95%] mx-auto mt-[15px]"/>
                    <div className="flex flex-col w-[95%] mx-auto h-[144px]  rounded-[25px] bg-[#f1f1f1] mt-[23px]">
                        <div className=" text-[16px] text-left font-poppins font-bold ml-[22px] mt-[12px]">Top-Tier Merchandising/<br></br> IT Executives:</div>
                        <div className=" text-[10px] text-left font-poppins font-normal ml-[22px] w-[87%] leading-[1 rem]">At Sun Knits, our exceptional IT and merchandising team possesses unmatched sourcing capabilities and a relentless drive for innovation. We bring a distinctive approach to the table, coupled with seamless task recording for unparalleled operational efficiency.</div>
                    </div>
                    <img src={infrasquare2} className="w-[95%] mx-auto mt-[15px]"/>
                    <div className="flex flex-col w-[95%] mx-auto h-[144px]  rounded-[25px] bg-[#E8F1FF] mt-[23px]">
                        <div className=" text-[16px] font-poppins font-bold ml-[22px] mt-[19px]">Lounging & Display Facility:</div>
                        <div className=" text-[10px] font-poppins font-normal ml-[22px] mt-[3px] w-[87%] leading-[1 rem]">At Sun Knits, our commitment extends beyond production infrastructure. Experience our products in an elegantly curated display area, complemented by a dedicated lounging space. Buyers can relax and engage in real-time, hands-on exploration of our distinguished portfolio.</div>
                    </div>
                    <img src={infrasquare3} className="w-[95%] mx-auto mt-[15px]"/>
                    <div className="flex flex-col w-[95%] mx-auto h-[144px]  rounded-[25px] bg-[#fff2e9] mt-[23px]">
                        <div className=" text-[16px] text-left font-poppins font-bold ml-[22px] mt-[17px] ">Safety Protocols:</div>
                        <div className=" text-[10px] text-left font-poppins font-normal ml-[22px] mt-[3px] w-[87%] leading-[1 rem]">At Sun Knits, safety is paramount. Our meticulous efforts ensure a secure environment, equipped with advanced fire extinguishers and dedicated safety measures. From spacing considerations to comprehensive precautions, we prioritise the well-being of our workforce and workspace.</div>
                    </div>
                    <img src={infrasquare4} className="w-[95%] mx-auto mt-[15px]"/>
                    </div>
                 </div>
                 <div className="w-full h-[772px] bg-black mt-[32px]">
                 <div className="text-[22px] font-normal text-[#FF7E20] font-poppins leading-tight text-center pt-[34px]"><span className="font-bold">Weaving Revolution in <br></br></span> Apparel Durability</div>
                            <div className="text-[15px] font-normal text-white my-[24px] font-poppins text-center">Redefines Durability Standards in<br></br> Every Stitch</div>
                            <div className="text-[12px] font-normal text-[#c1c1c1] w-[71%] font-poppins text-center mx-auto leading-[1.5rem]">To explore the pinnacle of trendsetting innovation at Sun Knit Fashions, take a glance at a sizable sector that combines the latest technology with skilful construction. Perfect curves and stitches are delivered by imported machinery, and our spacious, high-tech unit breathes warmth.</div>
                            <div className="flex items-center justify-center text-[20px] text-[#e9e9e9] fond-semibold font-poppins mt-[28px]">Our Capacity</div>
                            <div className="flex items-center justify-center text-[20px] text-[#a9a9a9] fond-normal font-poppins">3,50,000 pieces/month</div>
                                <img src={stateart}  width='41px' height='75px' className="mx-auto mt-[22px]" alt="State of Art" />
                                <div className="ml-[24px]">
                                    <div className="text-[20px] font-bold text-white font-poppins text-center mt-[15px] mr-[12px]">State of Art</div>
                                    <div className="text-[10px] font-normal text-[#c1c1c1] font-poppins text-center mr-[12px]">Manufacturing Facility</div>
                                    </div>
                                      
                                    <div className="twosections flex mt-[22px]">
                                        <div className="w-[50%] flex flex-col h-[98px] bg-black">
                                <div className="ml-[14px] mt-[18px]">
                                <img src={sweing} width='81px' height='75px' className="mx-auto " alt="State of Art" />
                                    <div className="text-[20px] font-bold text-white font-poppins text-center mt-[12px]">6</div>
                                    <div className="text-[10px] font-normal  font-poppins text-center w-[74%] ml-[34px] text-[#c1c1c1] ">Fully Equipped <br></br>Production Line Setup</div>
                                </div>
                                        </div>
                                        <div className="h-[74px] w-[2px] bg-[#434343] mt-[42px]"></div>
                                        <div className="w-[50%] flex flex-col h-[98px] bg-black">
                                        <div className="ml-[-14px] mt-[18px]">
                                <img src={ship} width='51px' height='75px' className="mx-auto " alt="State of Art" />
                                    <div className="text-[20px] font-bold text-white font-poppins text-center mt-[12px]">13M+</div>
                                    <div className="text-[10px] font-normal  font-poppins text-center w-[74%] ml-[34px] text-[#c1c1c1] ">garments produced and <br></br>shipped over</div>
                                </div>
                                        </div>
                                    </div>
                 </div>

                 <div className="collbrators-section">
                 <div className="flex font-semibold text-[24px] text-black items-center justify-center my-[34px] font-poppins"> Collaborators</div>
                        <div className="flex text-[12px] font-light w-[88%] mx-auto mt-[-14px] font-poppins leading-[1.7rem] text-left">At Sun Knit Fashions, our commitment to quality extends beyond our walls. Collaborating with highly efficient subcontractors equipped with top-of-the-line knitting, dyeing, printing facilities, and whatever process that is associated with apparel execution, we set new industry standards. Our partners share our sustainable vision, holding certifications that validate our collective dedication to ethical and high-quality production. Together, we redefine excellence in the apparel industry.</div>
                        <div className="flex justify-center items-center mt-[17px]">
                            <img src={handsake} className="w-[71%]" />
                        </div>
                 </div>
                                  
                               <img src={certificate} className="text-center w-[9%] mx-auto mt-[42px] mb-[12px]"/>
                            <div className='text-[20px] font-bold text-[#33333] my-[24px] text-center font-poppins mt-[1px]'>Certifications</div>
                            <div className='text-[12px] font-normal text-white flex flex-col items-center justify-center font-poppins'>
                                <div className="text-black text-center mx-auto mt-[-20px] font-poppins font-medium w-[89%]">At Sun Knit Fashions, our aligned vendors hold <br></br>  GOTS and OEKO-TEX certifications, reinforcing our commitment to sustainability and ethical practices.</div>
                            </div>

                            <img src={trust} className="text-center w-[9%] mx-auto mt-[42px] mb-[12px]"/>
                            <div className='text-[20px] font-bold text-[#33333] my-[24px] text-center font-poppins mt-[1px]'>Trust</div>
                            <div className='text-[12px] font-normal text-white flex flex-col items-center justify-center font-poppins mt-[-18px]'>
                                <div className="text-black text-center mx-auto mt-[-2px] font-poppins font-medium">For decades, our enduring partnerships are built on<br></br> trust, with Sun Knit Fashions consistently ensuring <br></br> quality and seamless workflows, fostering lastingv<br></br> relationships with our valued partners.</div>
                            </div>

                            <img src={endtoend} className="text-center w-[9%] mx-auto mt-[42px] mb-[12px]"/>
                            <div className='text-[20px] font-bold text-[#33333] my-[24px] text-center font-poppins mt-[1px]'>End To End</div>
                            <div className='text-[12px] font-normal text-white flex flex-col items-center justify-center font-poppins mt-[-18px]'>
                                <div className="text-black text-center mx-auto mt-[-2px] font-poppins font-medium">From inception to completion, our expert partners<br></br> provides tailored solutions for every aspect <br></br> of the  garmenting process, exceeding<br></br> your expectations.</div>
                            </div>

                            
        <div className='xl:hidden sm:block'>
            <FooterMobile/>
        {/* <div className='footer w-[99%] h-[226px] bg-black mx-auto mt-[34px] flex '>
          <div className='flex flex-col w-[50%] bg-black h-[144px] ml-[42px] mt-[32px]'>
            <div className='font-normal text-white font-poppins text-[19px] pb-[14px]'>
              <Link to="/">
                Home
              </Link>
            </div>
            <div className='font-normal text-white font-poppins text-[19px] pb-[14px]'>
              <Link to="/about">About us</Link>

            </div>
            <div className='font-normal text-white font-poppins text-[19px] pb-[14px]'>
              <Link to="/infrastructure">Infrastructure</Link>

            </div>
            <div className='font-normal text-white font-poppins text-[19px]'>
              <Link to="/products">
                Products
              </Link>
            </div>
          </div>
          <div className='flex flex-col w-[50%] bg-black h-[144px] mt-[32px]'>
            <div className='font-normal text-white font-poppins text-[19px] mb-[14px]'>
              <Link to="/sustainability">
                Sustainbility
              </Link>
            </div>

            <div className='font-normal text-white font-poppins text-[19px]'>
              <Link to="/contactus">
                Conatct Us
              </Link>
            </div>
          </div>
        </div>
        <div className='bg-black w-[99%] mx-auto h-[544px]'>
          <div className='w-[50%] flex mx-auto'> <img src={sunknitLogo} className='h-[174px]' /></div>
          <div className='text-center text-white text-[15px] leading-[2.4rem]'>S.F.NO: 250/1,</div>

          <div className='text-center text-white text-[15px] leading-[2.4rem]'>24,25 Kanchi Kamatchi Nagar,</div>

          <div className='text-center text-white text-[15px] leading-[2.4rem]'>Kangayampalayam Pudur,Kangayam Road,</div>

          <div className='text-center text-white text-[15px]'>Tirupur - 641 064</div>

          <div className='h-[1px] w-[70%] bg-[#ffff] mt-[28px] mx-auto'></div>

          <div className='text-[17px] text-white font-medium text-center mt-[24px]'>Follow Us</div>
          <div className='flex flex-row gap-[25px] items-center justify-center mt-[24px]'>
            <img src={fblogo}></img>
            <img src={linkedinlogo}></img>
            <img src={youtubelogo}></img>
          </div>
          <div className='text-[14px] font-medium text-white text-center mt-[24px]'>2024  Sunknit Garments . All Rights Reserved</div>

        </div> */}
      </div>
              
            </div>


            {/* mobile view end*/}




        </div>

    )
}